<template>
    <div class="container">
        <h5 class="right-align">Min.</h5>
        <hr style="color: white">
        <h4></h4>

        <div class="row" v-for="dep in departures" :key="dep">
            <div class="col m2 l2 center">
                <h6 style="outline: 4px solid white; line-height: 50px" :style="'background-color: ' + dep.lineColor"><strong>{{ dep.line }}</strong></h6>
            </div>
            <div class="col m7 l7 offset-m1 offset-l1">
                <h6 style="line-height: 50px"><strong><span style="display: inline-block; line-height: normal;">{{ dep.destination }}</span></strong></h6>
            </div>
            <div class="col m2 l2 right-align">
                <h6 style="line-height: 50px"><strong>{{ dep.delta }}</strong></h6>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'

export default {
    name: 'Board',
    data() {
        return {
            departures: [

            ],
            ID: "de:09162:470"
        }
    },
    mounted() {
        M.AutoInit(undefined)
        this.updateBoard()
    },
    methods: {
        updateBoard: function () {
            const ID = this.ID
            const URL = `https://www.mvg.de/api/fahrinfo/departure/${ID}?footway=0`

            fetch(URL)
                .then(res => res.json())
                .then(data => {
                    console.log(data)

                    this.departures = data.departures.map(dep => {
                        const now = new Date()
                        const departAt = new Date(dep.departureTime)
                        const deltaMillis = Math.abs(departAt - now)
                        const deltaMinutes = Math.floor((deltaMillis / 1000) / 60)

                        return {
                            line: dep.label,
                            destination: dep.destination,
                            lineColor: dep.lineBackgroundColor,
                            delta: deltaMinutes
                        }
                    }).slice(0, 3)
                })
            setTimeout(this.updateBoard, 30_000)
        }
    }
}
</script>
