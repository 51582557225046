<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
html {
    background-color: blue;
    color: white;
}

h1 {
    color: white;
}

h2 {
    color: white;
}

h3 {
    color: white;
}

h4 {
    color: white;
}

h5 {
    color: white;
}

h6 {
    color: white;
}
</style>
